import React, { useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./Auth.css";
import { Link } from "react-router-dom";
import { forgotPasswordStart } from '../../store/actions/UserAction';
import { translate, t } from "react-multi-lang";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const ForgotPassword = (props) => {

    const forgotPasswrodSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required *"),
    });

    const handleForgotPassword = (values) => {
        props.dispatch(forgotPasswordStart(values));
    };

    return (
        <>
            <div className="auth-login-sec">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="bg-illustration">
                                <Link to="/">
                                    <Image
                                        className="navbar-link-svg"
                                        src={
                                            window.location.origin + "/assets/images/logo-whatnot.png"
                                        }
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="login">
                                <div className="login-box">
                                    <h4>{t("forgot_passwords")}</h4>
                                    <div className="login-form">
                                        <Formik
                                            initialValues={{
                                                email: "",
                                            }}
                                            validationSchema={forgotPasswrodSchema}
                                            onSubmit={(values) => handleForgotPassword(values)}>

                                            {({ touched, errors, isSubmitting, setFieldValue }) => (
                                                <Form noValidate>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        placeholder="E-mail Address"
                                                        className="form-input"
                                                        autoFocus={true}
                                                    />
                                                    <ErrorMessage component={"div"} name="email" className='text-danger text-right' />

                                                    <Button type="submit" className="login-btn mt-4">{t("forgot_passwords")}</Button>
                                                    <div className="login-footer mt-3">
                                                        {/* <h6>{t("already_have_an_account")}</h6> */}
                                                        <Link to="/login">{t("login_for")} {configuration.get("configData.site_name")}</Link>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ForgotPassword));
