import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./Auth.css";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import {
  userRegisterStart,
  usernameValidationStart,
  referralValidationStart,
} from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
import SocialButton from "../helper/SocialButton";
import { gapi } from "gapi-script";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RegisterIndex = (props) => {
  const [referalCode, setReferalCode] = useState("");
  const [userName, setUserName] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };


  const [errors, setErrors] = useState({
    email: "",
  });

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value)
      return t("should_not_contain_empty_space");
    return "";
  }

 

  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("name_is_required"))
      .matches(/^[aA-zZ\s]+$/, t("only_characters_are_allowed_for_this_field"))
      .trim(t("name_cannot_include_leading_and_trailing_spaces")),
    username: Yup.string().required("User Name is required *")
    .min(4, t("must_be_username_4_character"))
    .matches(/^.[aA-zZ0-9.@\s]+$/, t("only_characters_are_allowed_for_this_field"))
    .max(15, t("max_be_username_15_character")),
    email: Yup.string()
      .email(t("invalid_email_address_format"))
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, t("invalid_email_address_format"))
      .required(t("email_is_required")),
    password: Yup.string()
      .required(t("password_is_required"))
      .strict(true)
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_contain_6_characters")),
  });

  const trimValidaton = (value) => {
    if (value.trim() == "") {
      return t("name_cannot_include_leading_and_trailing_spaces");
    }
  };

  let referral = "";
  if (configuration.get("configData.is_referral_enabled") == 1) {
    const query = new URLSearchParams(props.location.search);
    referral = query.get("referral");
  }

  useEffect(() => {
    setReferalCode(referral);
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });
  }, []);

  const handleSignup = (values) => {
    console.log(values);
    props.dispatch(userRegisterStart(values));
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.trim() === username) {
        if (username !== userName) {
          setUserName(username);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        return t("username_cannot_include_leading_and_trailing_spaces");
      }
    } else {
      return t("must_contain_4_characters");
    }
  };

 

  const checkReferralCode = (event) => {
    event.preventDefault();

    if (referalCode) {
      props.dispatch(referralValidationStart({ referral_code: referalCode }));
    }
  };

  return (
    <>
      <div className="auth-login-sec">
        <Container>
          <Row>
            <Col md={6}>
              <div className="bg-illustration">
                <Link to="/">
                  <Image
                    className="navbar-link-svg"
                    src={configuration.get("configData.site_logo")}
                  />
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <div className="login register">
                <div className="login-box">
                  <h4>{t("register")}</h4>
                  {configuration.get("configData.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.FB_CLIENT_ID")}
                      onLoginSuccess={handleFacebookLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="facebook-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_facebook")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                    <SocialButton
                      provider="google"
                      key={"google"}
                      appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                      onLoginSuccess={handleGoogleLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="google-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_google")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}
                  <div className="login-form">
                    <Formik
                      initialValues={{
                        name: "",
                        username: "",
                        email: "",
                        password: "",
                        referral_code: referral ? referral : "",
                      }}
                      validationSchema={registerSchema}
                      onSubmit={(values) => handleSignup(values)}
                    >
                      {({ touched, errors, isSubmitting, setFieldValue }) => (
                        <Form noValidate>
                          {console.log(errors)}
                          <Field
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="form-input"
                            autoFocus={true}
                            validate={trimValidaton}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger text-right"
                          />

                          <Field
                            type="text"
                            name="username"
                            placeholder="User Name"
                            className="form-input"
                            validate={handleUsernameValidation}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="username"
                            className="text-danger text-right"
                          />

                          {props.validation.isInValid && !errors.username && (
                            <div className="text-danger text-right">
                              {t("username_already_taken")}
                            </div>
                          )}
                          {props.validation.isValid && !errors.username && (
                            <div className="text-success text-right">
                              {t("looks_good")}
                            </div>
                          )}

                          <Field
                            type="email"
                            name="email"
                            placeholder={t("email_address")}
                            className="form-input"
                            
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger text-right"
                          />

                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={t("password")}
                            className="form-input"
                            validate={passwordValidation}
                          />
                           <div
                        className="register-toggle-password"
                        onClick={togglePasswordVisiblity}
                      >
                        {showPassword?<FaEyeSlash/>:<FaEye/>}
                      </div>{" "}
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />

                          {configuration.get(
                            "configData.is_referral_enabled"
                          ) == 1 && (
                              <>
                                <Field
                                  type="text"
                                  name="referral_code"
                                  placeholder="Referral Code(Optional)"
                                  className="form-input"
                                  onChange={(e) => setReferalCode(e.target.value)}
                                />
                                <div className="check-referral-link">
                                  <div
                                    className="text-primary h4 mt-0"
                                    onClick={checkReferralCode}
                                    role="button"
                                  >
                                    {t("check_referral_code_valid")}
                                  </div>
                                </div>
                              </>
                            )}
                          <Button
                            type="submit"
                            className="login-btn mt-4"
                            disabled={props.signup.buttonDisable}
                          >
                            {props.signup.loadingButtonContent !== null
                              ? props.signup.loadingButtonContent
                              : "SIGN UP"}
                          </Button>
                          <div className="forget-pass">
                            <h6>{t("already_have_an_account")}</h6>
                            <Link to="/login">
                              {/* {t("")}{" "}
                              {configuration.get("configData.site_name")} */}
                              {t("login")}
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
  validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(RegisterIndex));
