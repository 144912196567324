import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Badge,
  Media,
  Image,
} from "react-bootstrap";
import "./Product.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchUserProductsForOwnerStart,
  fetchMoreUserProductsForOwnerStart,
} from "../../../store/actions/ProductsAction";
import { deleteUserProductStart } from "../../../store/actions/ProductsAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { translate, t } from "react-multi-lang";
import ProductHeader from "./ProductHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Tooltip from "@material-ui/core/Tooltip";
import InfiniteScroll from "react-infinite-scroll-component";

const ProductList = (props) => {
  const [searchKey, setSearchKey] = useState();

  // useEffect(() => {
  //   props.dispatch(fetchUserProductsForOwnerStart());
  // }, []);

  useEffect(() => {
    props.dispatch(
      fetchUserProductsForOwnerStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreUserProductsForOwnerStart({
        skip: props.products.data.user_products.length,
        take: 12,
      })
    );
  };

  const handleChange = (event) => {
    setSearchKey(event.target.value);
    props.dispatch(
      fetchUserProductsForOwnerStart({ search_key: event.target.value })
    );
  };

  return (
    <>
      <div className="product-list-sec">
        <Container>
          <h2>{t("product_list")}</h2>
          <div className="ecom-navbar">
            <ProductHeader />
          </div>
          <Row>
            <Col md={12}>
              <div className="table-wrap">
                <Form>
                  <Row>
                    <Col md={9}></Col>
                    <Col md={3} className="text-right">
                      <div className="form-group explore-location-dropdown mb-4">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control edit-reset"
                          value={searchKey}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <InfiniteScroll
                  dataLength={props.products.data.user_products.length}
                  next={fetchMoreData}
                  hasMore={
                    props.products.data.user_products.length <
                      props.products.data.total && props.products.errorCount < 2
                  }
                  loader={<h4>Loading...</h4>}
                >
                  <Table responsive="md">
                    <thead className="thead-primary">
                      <tr>
                        <th>{t("image")}</th>
                        <th>{t("product")}</th>
                        <th>{t("price")}</th>
                        <th>{t("quantity")}</th>
                        <th>{t("in_stock")}</th>
                        <th className="text-center">{t("action")}</th>
                      </tr>
                    </thead>
                    {props.products.loading ? (
                      <tbody>
                        {[...Array(2)].map((e, i) => (
                          <tr>
                            <td>
                              <Skeleton className="img" />
                            </td>
                            <td>
                              <Skeleton width={"100%"} />
                            </td>
                            <td>
                              <Skeleton width={"100%"} />
                            </td>
                            <td>
                              <Skeleton width={"100%"} />
                            </td>
                            <td>
                              <Skeleton width={"100%"} />
                            </td>
                            <td>
                              <Skeleton width={"100%"} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : props.products.data.user_products.length > 0 ? (
                      <tbody>
                        {props.products.data.user_products.map((product) => (
                          <tr className="alert" role="alert">
                            <td>
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${product.picture})`,
                                }}
                              ></div>
                            </td>
                            <td>
                              <div className="email">
                                <span>{product.name} </span>
                                <span>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: product.description,
                                    }}
                                  ></p>
                                </span>
                              </div>
                            </td>
                            <td>{product.price_formatted}</td>
                            <td className="quantity">{product.quantity}</td>
                            <td>
                              {product.is_outofstock == "0" ? (
                                <Badge className="unconfirmed-badge">
                                  {t("no")}
                                </Badge>
                              ) : (
                                <Badge className="confirmed-badge">
                                  {t("yes")}
                                </Badge>
                              )}
                            </td>
                            <td>
                              <div className="product-list-action-icons">
                                <Button
                                  type="button"
                                  className="close"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        t("delete_product_confirmation")
                                      )
                                    ) {
                                      props.dispatch(
                                        deleteUserProductStart({
                                          user_product_id:
                                            product.user_product_id,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <h6 className="custom_tooltip">
                                        {t("delete_product")}
                                      </h6>
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <span aria-hidden="true">
                                      <i className="fa fa-close"></i>
                                    </span>
                                  </Tooltip>
                                </Button>
                                <Button
                                  type="button"
                                  className="view"
                                  href={"/single-product/" + product.unique_id}
                                >
                                  <Tooltip
                                    title={
                                      <h6 className="custom_tooltip">
                                        {t("view_product")}
                                      </h6>
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <span>
                                      <i className="fa fa-eye"></i>
                                    </span>
                                  </Tooltip>
                                </Button>
                                <Button
                                  type="button"
                                  className="edit"
                                  href={
                                    "/edit-product/" + product.user_product_id
                                  }
                                >
                                  <Tooltip
                                    title={
                                      <h6 className="custom_tooltip">
                                        {t("edit_product")}
                                      </h6>
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <span>
                                      <i className="fa fa-edit"></i>
                                    </span>
                                  </Tooltip>
                                </Button>
                              </div>
                              <Link
                                to={`/view-order/${product.unique_id}/${product.user_product_id}`}
                                className="single-orders-view"
                              >
                                <span>{t("view_orders")}</span>
                              </Link>
                              {/* <Link
                                to={`/product-gallery/${product.unique_id}/${product.user_product_id}`}
                                className="single-orders-view"
                              >
                                <span>{t("gallery")}</span>
                              </Link> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div>
                        <NoDataFound></NoDataFound>
                      </div>
                    )}
                  </Table>
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  products: state.userProducts.productsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ProductList));
