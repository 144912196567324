import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./Auth.css";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  userLoginStart,
  userRegisterStart,
  usernameValidationStart,
  referralValidationStart,
} from "../../store/actions/UserAction";
import SocialButton from "../helper/SocialButton";
import { gapi } from "gapi-script";

const LoginIndex = (props) => {
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_address_format"))
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, t("invalid_email_address_format"))
      .required(t("email_is_required")),
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_contain_6_characters")),
  });

  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });
  }, []);

  return (
    <>
      <div className="auth-login-sec">
        <Container>
          <Row>
            <Col md={6}>
              <div className="bg-illustration">
                <Link to="/">
                  <Image
                    className="navbar-link-svg"
                    src={configuration.get("configData.site_logo")}
                  />
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <div className="login">
                <div className="login-box">
                  <h4>{t("logins")}</h4>

                  {configuration.get("configData.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.FB_CLIENT_ID")}
                      onLoginSuccess={handleFacebookLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="facebook-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_facebook")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}
                  {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                    <SocialButton
                      provider="google"
                      key={"google"}
                      appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                      onLoginSuccess={handleGoogleLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="google-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_google")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}

                  <div className="login-form">
                    <Formik
                      initialValues={{
                        email: configuration.get("configData.demo_user_email"),
                        password: configuration.get(
                          "configData.demo_user_password"
                        ),
                      }}
                      validationSchema={loginSchema}
                      onSubmit={(values) => handleLogin(values)}
                    >
                      {({ touched, errors, isSubmitting, setFieldValue }) => (
                        <Form noValidate>
                          <Field
                            type="email"
                            name="email"
                            placeholder={t("email_address")}
                            className="form-input"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger text-right"
                          />

                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={t("password")}
                            className="form-input"
                          />
                           <div
                        className="login-toggle-password"
                        onClick={togglePasswordVisiblity}
                      >
                        {showPassword?<FaEyeSlash/>:<FaEye/>}
                      </div>{" "}
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />

                          <div className="forget-pass">
                            <Link to="/forgot-password">
                              {t("forgot_password")}
                            </Link>
                          </div>

                          <Button
                            type="submit"
                            className="login-btn"
                            disabled={props.login.buttonDisable}
                          >
                            {props.login.loadingButtonContent !== null
                              ? props.login.loadingButtonContent
                              : "Login"}
                          </Button>

                          <div className="login-footer">
                            <h6>{t("do_not_have_an_account")}</h6>
                            <Link to="/signup">
                              {" "}
                              {t("signup_for")}{" "}
                              {configuration.get("configData.site_name")}
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LoginIndex));
